import { render, staticRenderFns } from "./2024.vue?vue&type=template&id=45af0f6e&scoped=true"
import script from "./2024.vue?vue&type=script&scope=true&lang=js"
export * from "./2024.vue?vue&type=script&scope=true&lang=js"
import style0 from "./2024.vue?vue&type=style&index=0&id=45af0f6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45af0f6e",
  null
  
)

export default component.exports