<template>
  <div class="about">
    <section>
      <fvl-col></fvl-col>
       <!-- <div class="container" style="margin-left: auto;margin-right: auto;">
           <div class="row">
              <div class="col-lg-4">
                <div class="bg-img radius-10 md-mb50"><img id="aboutImage"></div>
              </div>
              <div class="col-lg-8">
                <div class="bg-img radius-10"><img id="aboutImage2"></div>
              </div>
            </div>
       </div> -->
   </section>

        <!-- <div class="container" style="padding: 10px 10px;height: 100vh;">
             <p class="org-description is-size-4" style="font-weight:bold;color: #01F0CC;">關於空間</p>
           <div v-html="apiData7" style="color: azure;"></div>
        </div> -->
          <hr style="color: aliceblue;">
             <info-bar></info-bar>
             <foot-bar2></foot-bar2>
</div>

</template>


<script>
import axios from 'axios';
// import { FvlCollect } from '../components';
import { FvlCollect,InfoBar, FootBar2} from '../components';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { TextPlugin } from 'gsap/dist/TextPlugin.js';
export default {
  name: 'Collection',
  components: {
    'fvl-col':FvlCollect,
     'info-bar':InfoBar,
     'foot-bar2':FootBar2
  },
  data () {
    return {
      msg: 'FUTURE VISION LAB ',
      schedules:[],
      apiData1:{},
      apiData2:{},
      apiData3:{},
      apiData4:{},
      apiData5:{},
      apiData6:{},
      apiData7:{},
    }
  }, mounted(){
    axios.all([
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities?search=FUTURE%20VISION%20LAB%202024'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/4'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/6162e143-f315-4a45-a9b4-830c8b3d8704'),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2OTE1NzcyMTAyMzA=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2ODI0MTc0NjgwNDM=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities/443e7a5e-6d51-4d39-9bc0-fd71e579da09'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/87db0364-e198-4abb-b4e9-d6c3c70ebd68'),
      axios.get('https://unzip-clab-api.clab.org.tw/work-photo/1ae1a839-6db4-42f8-a6e3-831c59623ca7/MDIuU19fMjQxNjY0MTEuanBnLTE2MzMxNzA4MDYzNDgtMzU4Mw==.jpeg',{responseType:'blob'})
    ])
    .then(axios.spread( (response1, response2 ,response3,response4,response5,response6,response7,response8) => {
       //  const reader = FileReader();
      //宣告Filereader
      this.apiData1 = response1.data;
      this.apiData2 = response2.data;
      this.apiData3 = response3.data;
      this.apiData4 = response4.data;
      this.apiData5 = response5.data;
      this.apiData6 = response6.data;
      this.apiData7 = response7.data.work_zh.proposal;
      this.apiData8 = response8.data;
      let imageNode = document.getElementById('aboutImage');
      let imageNode2 = document.getElementById('aboutImage2');
      let imgUrl = URL.createObjectURL(response5.data);
      let imgUrl2 = URL.createObjectURL(response8.data);
      imageNode.src = imgUrl;
      imageNode2.src = imgUrl2;
      
      console.log(this.apiData1);
      console.log(this.apiData2);
      console.log(this.apiData3);
      console.log(this.apiData4);
      console.log(this.apiData5);
      console.log(this.apiData6);
      console.log(this.apiData7);
      console.log(this.apiData8);
    }))
  //  .catch(err=>{
  //    console.log(err.response);
  //    });
  }
}
</script>
<style lang="scss" scoped>
  /* // .org-description {
  //   margin-top: 50px;
  //   padding-bottom: 10px;
  // } */
  .about {
    background-color: black;
    // height:100vh;
    width: 100vw;
  }
  .hero-body{
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display:block;
    position: relative;
    overflow: hidden;
  }
  .aboutImage{
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .container{
    max-width: 960px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
  }
  .col-lg-4{
    flex:0 0 auto;
    width:33.33333333%;
  }
  .col-lg-8{
    flex:0 0 auto;
    width:66.66666667%;
  }
  .row{
    --bs-gutter-x:1.5rem;
    --bs-gutter-y:0;
    display:flex;
    flex-wrap:wrap;
    margin-top:calc(var(--bs-gutter-y) * -1);
    margin-right:calc(var(--bs-gutter-x) * -.5);
    margin-left:calc(var(--bs-gutter-x) * -.5);
  }
  .section-padding{
    padding:90px 0;
  }

  img{
    border-radius: 10px;
    height:290px;
  }
  .md-mb50{
    margin-bottom: 90px !important;
  }
  .pg-about .bg-img{
    height:220px;
    background-size:cover;
    background-position:center center;
  }
  .slider{
    animation-name:future-vision-lab;
    animation-duration:150s;
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    animation-delay: 0;
    display: flex;
  }
  .item{
    position: relative;
    padding: 0 30px;
    display: flex;
  }
 
  @keyframes future-vision-lab{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  .videobox{
    position: relative;
    margin-bottom: 5rem;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

  }

  .videobox iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


</style>
<style lang="css" scoped src="../bootstrap.min.css">
</style>

