<template>
  <div class="about">
   
<!-- ---------CSS animation About us-------------- -->
<div class="animation" style="width: 100%;overflow: hidden;padding-top: 82px;">
         <div class="slider">
             <div class="item" style="position: relative;">
               <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #000;">About us</h4>
             </div>
             <div class="item"  style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;">About us</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #000;">About us</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;">About us</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #000;">About us</h4>
            </div>
         </div>
   </div>
  <!-- -----------------CSS animation About us end ------------------- -->

 <section class="pg-about section-padding sub-bg">
    <div class="container" style="margin-left: auto;margin-right: auto;">
      <div class="row">
        <div class="col-lg-4">
          <div class="bg-img radius-10 md-mb50"><img id="aboutImage"></div>
        </div>
        <div class="col-lg-8">
          <div class="bg-img radius-10" style="height: 100%;"><img id="aboutImage2"></div>
        </div>
      </div>
    </div>
</section>

    <div class="container " style="width:90%; padding:10px 10px;display: flex;flex-direction: column;">
      <p class="org-description is-size-4" style="font-weight:bold;">關於未來實驗室</p>
      <!-- <v-text><p>{{apiData7}}</p></v-text> -->
      <div v-html="apiData7"></div>

      <p class="org-description is-size-4" style="font-weight:bold;margin-top: 2rem;">ABOUT FUTURE VISION LAB<p>
      </p>OSI is a platform to introduce and share open source technologies consisting of mostly server side scripts, 
      team collaboration tools, and small utility software like our PDF compressors built for Linux desktops users. 
      Contributors range from founders and staff of 3DF working on both work related and personal projects all the way to contributors from our partners and friends. 
      The goal is to sustain a platform for sharing resources in every aspect of running successful open source projects and to participate in the greater Open Source community together.</p>

    <div class="videobox">
      <iframe  src="https://www.youtube.com/embed/AYAP7x3XrdU?si=okQqkIkMJb5PLZed" title="YouTube video player" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
     </div>
    </div>
    <info-bar></info-bar>
    <foot-bar2></foot-bar2>
</div>

</template>


<script>
import axios from 'axios';
import { InfoBar, FootBar2} from '../components';
export default {
  name: 'About',
  components: {
     'info-bar':InfoBar,
     'foot-bar2':FootBar2
  },
  data () {
    return {
      msg: 'FUTURE VISION LAB ',
      schedules:[],
      apiData1:{},
      apiData2:{},
      apiData3:{},
      apiData4:{},
      apiData5:{},
      apiData6:{},
      apiData7:{},
    }
  }, mounted(){
    axios.all([
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities?search=FUTURE%20VISION%20LAB%202024'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/4'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/6162e143-f315-4a45-a9b4-830c8b3d8704'),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2OTE1NzcyMTAyMzA=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2ODI0MTc0NjgwNDM=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities/443e7a5e-6d51-4d39-9bc0-fd71e579da09'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/87db0364-e198-4abb-b4e9-d6c3c70ebd68'),
      axios.get('https://unzip-clab-api.clab.org.tw/work-photo/1ae1a839-6db4-42f8-a6e3-831c59623ca7/MDIuU19fMjQxNjY0MTEuanBnLTE2MzMxNzA4MDYzNDgtMzU4Mw==.jpeg',{responseType:'blob'})
    ])
    .then(axios.spread( (response1, response2 ,response3,response4,response5,response6,response7,response8) => {
       //  const reader = FileReader();
      //宣告Filereader
      this.apiData1 = response1.data;
      this.apiData2 = response2.data;
      this.apiData3 = response3.data;
      this.apiData4 = response4.data;
      this.apiData5 = response5.data;
      this.apiData6 = response6.data;
      this.apiData7 = response7.data.work_zh.proposal;
      this.apiData8 = response8.data;
      let imageNode = document.getElementById('aboutImage');
      let imageNode2 = document.getElementById('aboutImage2');
      let imgUrl = URL.createObjectURL(response5.data);
      let imgUrl2 = URL.createObjectURL(response8.data);
      imageNode.src = imgUrl;
      imageNode2.src = imgUrl2;
      
      console.log(this.apiData1);
      console.log(this.apiData2);
      console.log(this.apiData3);
      console.log(this.apiData4);
      console.log(this.apiData5);
      console.log(this.apiData6);
      console.log(this.apiData7);
      console.log(this.apiData8);
    }))
  //  .catch(err=>{
  //    console.log(err.response);
  //    });
  }
}
</script>
<style lang="scss" scoped>
  /* // .org-description {
  //   margin-top: 50px;
  //   padding-bottom: 10px;
  // } */
  .about {
    // padding-bottom: 50px;
  }
  .hero-body{
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display:block;
    position: relative;
    overflow: hidden;
  }
  .aboutImage{
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
 
  .container{
    max-width: 960px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
  }
  .col-lg-4{
    flex:0 0 auto;
    width:33.33333333%;
  }
  .col-lg-8{
    flex:0 0 auto;
    width:66.66666667%;
  }
  .row{
    --bs-gutter-x:1.5rem;
    --bs-gutter-y:0;
    display:flex;
    flex-wrap:wrap;
    margin-top:calc(var(--bs-gutter-y) * -1);
    margin-right:calc(var(--bs-gutter-x) * -.5);
    margin-left:calc(var(--bs-gutter-x) * -.5);
  }
  .section-padding{
    padding:50px 0;
  }

  img{
    // border-radius: 10px;
    height:290px;
  }
  .md-mb50{
    margin-bottom: 90px;
  }
  .pg-about .bg-img{
    height:220px;
    background-size:cover;
    background-position:center center;
  }
  .slider{
    animation-name:future-vision-lab;
    animation-duration:60s;
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    animation-delay: 0;
    display: flex;
  }
  .item{
    position: relative;
    padding: 0 30px;
    display: flex;
  }
 
  @keyframes future-vision-lab{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  .videobox{
    position: relative;
    margin-bottom: 5rem;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

  }

  .videobox iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:720px) {
    .row{
      justify-content: center;
      align-items: center;
    }
    .md-mb50{
      margin-bottom: 0 !important;
    }
  #aboutImage{
    padding:20px;
    height:100%;
    width: 100%;

  }
  #aboutImage2{
    padding:20px;
    height:100%;
    width: 100%;
  }
}

</style>
<style lang="css" scoped src="../bootstrap.min.css">
</style>

