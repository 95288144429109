<template>
<v-app>
<div id="app" style="scroll-behavior: smooth;background-color: black;">

<head>
    <meta charset="UTF-8">
    <meta name = "viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie-edge">
</head>
       <div class="filter gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align clearfix" style="padding-top:90px;">
          <ul style="margin-left: 0;border:1px gray solid;border-radius:30px;padding: 15px;">
            <li><a href="#" class="active" data-filter="*" @click="type='All'">All</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class1"@click="type='2018'">2018</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class2"@click="type='2019'">2019</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class3"@click="type='2020'">2020</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class1"@click="type='2021'">2021</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class5"@click="type='2022'">2022</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6"@click="type='2023'">2023</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6"@click="type='2024'">2024</a><span class="kleanity-separater">/</span></li>
        </ul>
       </div>
       
       <Transition name="fade1">
       <template v-if="type === 'All'">
      
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 2px red solid;">
                   
               <div class="hashtag-container">
                  <div class="hashtag">
                     <!-- <ul style="margin-left: 0;border:1px gray solid;border-radius:30px;padding: 15px;"> -->
                       <div class="hashtag-box">
                           <p data-filter=".class1"@click="type='2018'">2018</p>
                       </div>
                      <div class="hashtag-box">
                          <p  data-filter=".class2"@click="type='2019'">2019</p>
                      </div>
                      <div class="hashtag-box">
                          <p  data-filter=".class3"@click="type='2020'">2020</p>
                      </div>
                       <div class="hashtag-box">
                           <p>2021</p>
                      </div>
                      <div class="hashtag-box">
                           <p>2022</p>
                      </div>
                       <div class="hashtag-box">
                          <p>2023</p>
                       </div>
                       <div class="hashtag-box">
                          <p>2018</p>
                      </div>
                       <div class="hashtag-box">
                          <p>2019</p>
                       </div>
                       <div class="hashtag-box">
                          <p>2020</p>
                      </div>
                       <div class="hashtag-box">
                          <p>2021</p>
                       </div>
                       <div class="hashtag-box">
                           <p>2022</p>
                        </div>
                       <div class="hashtag-box">
                           <p>2023</p>
                       </div>
                       <div class="hashtag-box">
                          <p>2018</p>
                        </div>
                    </div>
                 </div>
         <!-- </ul> -->
                 
                  <div class="grids" >
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
                     </div>
              
               </div>
       </div>

    </template>
   </Transition>
   
       <Transition name="fade1">
       <template v-if="type === 'artist'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px blue solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>
 

    <Transition name="fade1">
       <template v-if="type === '2018'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
<!--                   
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px green solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div> -->

                        <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                           <div class="row grid max-margin" style="margin: 0 auto;border: 2px blue solid;">
                   
                   <div class="hashtag-container">
                      <div class="hashtag">
                         <!-- <ul style="margin-left: 0;border:1px gray solid;border-radius:30px;padding: 15px;"> -->
                           <div class="hashtag-box">
                               <p data-filter=".class1"@click="type='2018'">2018</p>
                           </div>
                          <div class="hashtag-box">
                              <p  data-filter=".class2"@click="type='2019'">2019</p>
                          </div>
                          <div class="hashtag-box">
                              <p  data-filter=".class3"@click="type='2020'">2020</p>
                          </div>
                           <div class="hashtag-box">
                               <p>2021</p>
                          </div>
                          <div class="hashtag-box">
                               <p>2022</p>
                          </div>
                           <div class="hashtag-box">
                              <p>2023</p>
                           </div>
                           <div class="hashtag-box">
                              <p>2018</p>
                          </div>
                           <div class="hashtag-box">
                              <p>2019</p>
                           </div>
                           <div class="hashtag-box">
                              <p>2020</p>
                          </div>
                           <div class="hashtag-box">
                              <p>2021</p>
                           </div>
                           <div class="hashtag-box">
                               <p>2022</p>
                            </div>
                           <div class="hashtag-box">
                               <p>2023</p>
                           </div>
                           <div class="hashtag-box">
                              <p>2018</p>
                            </div>
                        </div>
                     </div>
             <!-- </ul> -->
                     
                      <div class="grids" >
                        <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                           <div class="imagewrap">
                                <img :src ="item.img">
                            </div>
                         </div>
                         </div>
                  
                   </div>
               
       </div>
    </template>
   </Transition>

   <Transition name="fade1">
    <template v-if="type === '2019'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px yellow solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>

    <Transition name="fade1">
    <template v-if="type === '2020'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px purple solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
</Transition>

<Transition name="fade1">
    <template v-if="type === '2021'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px brown solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>

<Transition name="fade1">
    <template v-if="type === '2022'">
       <div class="girid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px gray solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
</Transition>


<Transition name="fade1">
    <template v-if="type === '2023'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px orange solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
   </Transition>

  <Transition name="fade1">
    <template v-if="type === '2024'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px white solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>

    <Transition name="fade1">
    <template v-if="type === '徵件作品'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px orchid solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>

    <Transition name="fade1">
    <template v-if="type === 'SAT'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px orange solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>

    <Transition name="fade1">
    <template v-if="type === '開場影像'">
       <div class="grid-container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px orangered solid;">
                    <div class="col-md-6 card mb-80" v-for="(itex, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    </Transition>
</div>
<info-bar></info-bar>
<foot-bar2></foot-bar2>
  </v-app>
</template>


<script scope>
import axios from 'axios';
import '../google-fonts.css';
import '../kleanity-style-custom.css';
import '../page-builder.css';
import '../style-core.css';
import '../font-awesome.css';
import {InfoBar,FootBar2} from '../components';

export default {
  name: 'All',
  components: {
     'info-bar':InfoBar,
     'foot-bar2':FootBar2
  },
  data () {
    return {
      msg: 'Welcom to FUTURE VISION LAB',
      show:true,
      type:'All',
      products:[],
      productData : {
       
      },
    }
  },
  mounted(){
        axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules?limit=25').then((response)=>{
        this.products = response.data
        console.log(response.data)}).catch((error) => console.log(error))
  },

}



</script>
<style lang="scss" scoped>

.row{
    width: 100vw;
    height:auto;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items:flex-start;
    padding: 1rem 1rem;
   
}
.hashtag-container{
   width: 20%;
   display: flex;
   justify-content: flex-end;
}
.hashtag{
   width: 80%;
   height:600px;
   border-radius: 30px;
   border:1px white solid;
   padding:20px;
   display: flex;
   justify-content: space-evenly;
   align-content: flex-start;
   flex-wrap: wrap;
}
.hashtag-box{
   border:1px rgb(53, 53, 53) solid;
   height:2rem;
   border-radius:17px;
   padding:0.2rem;
}
.hashtag-box:hover{
   background-color: aliceblue;
   color: black;
}

.grids{
   width: 80%;
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  padding: 2rem;


}

@media only screen and (max-width:720px) {
  .row{
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
  .hashtag{
   width: 100%;
   height:300px;
   margin-bottom: 2rem;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
  }
  .hashtag-container{
   width: 100%;
  }
  .grids{
  padding: 0;

  }
  
}



.gallery{
   position: relative;
   height: 5280px;
}
.card {
   background: #eee;
   width:250px;
   height:250px;
   margin-left: 10px;
   margin-right: 10px;
   display: inline-block;
   justify-content: center;
   flex-wrap: wrap;
   align-items: center;

}
.mb-80{
   margin-bottom: 60px !important;
}

@media only screen and (max-width:768px){
  .card{
    width:120px;
    height:120px;
    margin: 10px;
  }
  .mb-80{
   margin-bottom: 20px !important;
}
 }


.col-md-6{
    flex:0  0 auto;

}


.text-center{
    text-align: center !important;
}


// ------------------circle---------------------

.bg-neutral-200{
    --tw-bg-opacity:1;
    background-color:rgb(229 229 229 / var(--tw-bg-opacity));
  }
  .h-44{
  height: 11rem;
}
.w-44{
  width: 11rem;
}
.mt-16{
  margin-top: 4rem;
}
.-z-20{
  z-index: -20;
}
.-left-20{
  left:50rem;
}
.border-t{
    border-top-width:1px;
  }
  .relative{
    position: relative;
  }
  .rounded-full{
   border-radius: 9999px;
}








.fade1-enter-active{
 animation: bounce-in 1s;
}
.fade1-leave-active{
transition: bounce-in 1s reverse;
}
@keyframes bounce-in{
   0%{
      transform: scale(0.8);
   }
   100%{
      transform: scale(1);
   }
}












</style>

