<template>
   <section>
    <head>
      <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap" rel="stylesheet">

   <title>FUTURE VISION LAB 2024</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width">
    <meta property="og:image" content="../assets/logo2.png">
               <meta property="og:image:type" content="image/png">
              <meta property="og:image:width" content="436">
              <meta property="og:image:height" content="436">
              <meta property="og:title" content="FUTURE VISION LAB 2024">
              <meta property="og:description" content="2020年年初，空總臺灣當代文化實驗場C-LAB成立了「科技媒體實驗平台」，著重於「媒體實驗創新」與「社會鏈結」而生，透過有機的實驗室型態，以跨域、共創、協作方式，打造文化與科技的共創環境。">
    </head>
        <section class="home" style="background-color:white;">
       
               <div class="banner-box">
                <div class="banner" style="display: flex;justify-content: center;align-items: center;">
                  <div class="coming">
                    <h1>Coming<br>soon</h1>
                  </div>
                  <div class="coming_img">
                    <img src="../assets/comingsoon.png">
                    </div>
                       <div class="banner-back" style="filter:blur(30px);transform: scale(2);">
                          <banner-background></banner-background>
                        </div>
                </div>
              </div>
  </section>

</section>
</template>

<script>
// import "../jquery.js";
import axios from 'axios';
import '../parallaxedEffect.js';
import {ThreeJs} from '../components';
import { FootBar } from '../components';
import { InfoBar } from '../components';
import {gsap} from "gsap";
import{Anibackground}from '../components';
import { BannerBackground } from '../components';
import {Background} from '../components';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { TextPlugin } from 'gsap/dist/TextPlugin.js';
// import { BooleanKeyframeTrack, LineLoop, LoopRepeat, RedFormat } from 'three';
import { defineComponent } from 'vue'

let timer 
const interval = 4000;


// import 'font-awesome/css/font-awesome.min.css';
// import 'font-awesome/css/font-awesome.css';
// import 'font-awesome/fonts/fontawesome-webfont.eot';
// import 'font-awesome/fonts/fontawesome-webfont.svg';
// import 'font-awesome/fonts/fontawesome-webfont.ttf';
export default defineComponent({
  name: 'fvl-fest-2024',
  components: {
     'three-js':ThreeJs,
     'foot-bar':FootBar,
     'info-bar':InfoBar,
     'ani-back':Anibackground,
     'banner-background':BannerBackground,
     'back-ground':Background,
  },
  
  
  data () {
    return {
      slideData:[],
      show:true,
      type:'All',
      products:[],
      products2:[],
      productData : {
      },
      banner:"../assets/2024banner.png",
      link:'early-bird',
      // tickets:[
      // {early:'$200'},
      //  {pre:'$180'},
      //  {normal:'$220'},
      //  {student:'$195'}
      // ],
      transitionName:'left-in',
      show:0,
      msg: 'A quick-start vue.js frontend development template.',
      imgs:[
        {type:'img',src:'./imgs/1.jpeg'},
        // {type:'img',src:'./imgs/2.jpeg'},
        // {type:'img',src:'./imgs/3.jpeg'},
        // {type:'video',src:"https://www.youtube.com/embed/videoseries?mute=1&autoplay=1;si=fCFjJb6JtgHHtucM&amp;list=PLXJ_MjvcL-q5V-vae8rmre2Rz4ZTjB6gF"},
        // {type:'video',src:"https://www.youtube.com/embed/yM-Q5qNNVbM?mute=1&autoplay=1;si=yX0PfbGKm9-szmuE" }
      ],
    }
  },

  

  methods:{
    // jump(id){
    //   this.$router.push(
    //     { name:item.link,
    //      query:{id}}
    //      );
    // },
    chosework(index){
          // console.log(item);
          // for (var i = 0; i < this.products.length; i++) {
          // console.log(i);
          console.log(index)

        // }
          
    },
     
    plus(){
   console.log('已選擇預售票');

    },

    setShow(index){
        this.show = index 
      },
      nextShow(){
        this.show ++
      },

      slideCtrl(slidesToShow = 1){
        if(slidesToShow > 0 ){
          const shiftItem = this.slideData.shift();
          this.slideData.push(shiftItem);
          return;
        }
        if(slidesToShow <0){
          const shiftItem = this.slideData.pop();
          this.slideData.unshift(shiftItem);
        }
      },
       
},
  watch:{
    show(nVal,oVal){

          if(nVal<0){
        
          this.show = this.imgs.length -1 
          }else if(nVal >this.imgs.length -1){
     
            this.show = 0
          }else{
            if(oVal < 0 )this.transitionName = 'left-in'
            else if (oVal > this.imgs.length -1 )this.transitionName = 'right-in'
            else  this.transitionName = nVal>oVal ?'right-in' : 'left-in'
          }
    }
  },
   
  
onMounted(){
  parallaxie('.back-image.parallaxie');
},
  mounted(){
    console.log(this.$route)

    // const coords ={x:0,y:0};
    // const bubles = document.querySelectorAll(".buble");

    // bubles.forEach(function(buble){
    //   buble.x = 0;
    //   buble.y = 0;
    // })


    // window.addEventListener("mousemove",function(e){
    // coords.x = e.pageX;
    // coords.y = e.pageY;
    // // console.log(coords); 

    // gsap.to(bubles,{
    //   '--x':`${x}%`,
    //   '--y':`${y}%`,
    //   duration:0.3,
    //   ease:'sine.out'
    // })
    // });


    


    // function animateBubles(){

    //   let x = coords.x;
    //   let y = coords.y;

    // bubles.forEach(function(buble,index){
    //   buble.style.left = x -12 +"px";
    //   buble.style.top = y -12 +"px";
    //   buble.x = x;
    //   buble.y = y;
    //   buble.style.scale = (20-index)/20;

    //   const nextBuble = bubles[index + 1] || bubles[0];
    //   x += (nextBuble.x - x) * 0.3;
    //   y += (nextBuble.y - y) * 0.3;
    // });

    // requestAnimationFrame(animateBubles);
    // }

    // animateBubles();


// ----------自動輪播----------------
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

// const shut = document.getElementsById('shut');

gsap.to('#shut',{
     top:-6,
     duration:0.01,
     scrollTrigger:{
      trigger:'.fvl_icon',
      start:"top 50%",
      end:"top 30%",
      toggleActions:'play reverse restart reverse',
      // markers:{
      //   startColor:"orange",
      //    endColor:"blue",
      //   fontSize:"2rem"
      // }
    }
}),


   
timer = setInterval(this.nextShow,interval),

axios.all([

  // axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works?search=Future%20Vision%20Lab&limit=25',{responseType:'json'}).then((response)=>{
    axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'}),
        // console.log(response.data)}).catch((error) => console.log(error));
        // for(i=0;i<=this.products.lenght;i++){
        //   i=0;
        // }

    axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'})
    // this.artists = response.data.artist
    // console.log(response.data)}).catch((error) => console.log(error));
    ]).then(axios.spread((response1,response2) => {
      this.products = response1.data.works;
      this.products2 = response2.data.artists;

      console.log(this.products);

      console.log(this.products2);
    }))   .catch(err=>{
    console.log(err.response);
    });
  }

  



})

</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");

.banner-box{
  height: 100vh;
  width:100vw;
  margin: 0 auto;
  position: relative;
  // margin-top:4rem;
}
.banner{
  height: 100%;width:100%;object-fit: cover;overflow: hidden;z-index: 1;position: absolute;top: 0;
}
.coming{
  position: absolute;
  z-index: 999;
  color: white;
  font-size:120px;
  text-align: center;
  text-transform: uppercase;
  // font-family: 'Impact', Times, serif;
  font-family: "Gill Sans", monospace;
}
.coming_img{
  position: absolute;
  bottom:-10px;
  // filter: blur(10px);
}
.banner-back{
  position: absolute;
  top: 0;
  z-index: -1;
}
.fvl-logo-img{
  position: absolute;
  top: 10%;
  z-index: 1;
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
}
// .banner{
//   position: relative;
//   width:100%;
//   // height:300px;
//   height: 100vh;
//   margin: 0 auto;
//   overflow:hidden;
//   object-fit: cover;
// }

// .buble{
//   z-index: 999;
//   background-color: #000000;
//   // pointer-events:none;
//   width:20px;
//   height:20px;
//   border-radius: 100px;
//   position: absolute;
//   pointer-events: none;
//   clip-path: circle(100px at var(--x,));
//   // transform: translate(-50%,-50%);
//   transition: 0.05s;
//   // border: px rgb(255, 255, 255) solid;
//   top:0;
//   left: 0;
//   // animation: colorgen 5s infinite;
// }

// @keyframes colorgen {
//   0% {
//     opacity: 1;
//     filter: hue-rotate(0deg);
//   }
//   100% {
//     opacity: 1;
//     filter: hue-rotate(360deg);
//   }
// }
// .banner-box:hover{
//   #{
// transform: scale(2,2);
// filter: blur(5px);
// opacity: 0.9;
// }
// }


@media only screen and (max-width:1024px) {
    .section {
       padding: 3rem 1rem;
    }
  }

  @media only screen and (min-width:1024px) {
    .section {
       padding: 3rem 0rem;
    }
  }

@media only screen and (min-width:850px) {
  .banner-box{
    margin: 0 auto;
    position: relative;
    height:100vh;
    overflow: hidden;
    width: 100vw;
    // margin-top: 4rem;
  }
    .banner {
        // height:600px;
        height: 100%;
        width: 100%;
    }
    .banner.img{
      height: 100%;
      width: 100%;
    }
    .banner-back{
    position: absolute;
    height:100%;
    top: 0;
    z-index: -1;
  }
  }


  // @media screen and (max-width: 850px) {
  //   .banner-box{
  //     height:100%;
  //   }
  // }
  

.page{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height:100%;
  overflow-x: hidden;

}
.right-in-enter{
    left: 100%;
}
.right-in-enter-active,.right-in-leave-active{
   transition: left 0.5s;
}
.right-in-enter-to,.right-in-leave{
     left: 0%;
}
.right-in-leave-to{
     left:-100%;
}

// -----------------------------
.slider-btn{
  position:absolute;
  top:50%;
  opacity: 0.8;
  // z-index:999;
  height:10%;
  width: 50px;
  border: none;
  outline:0;
  cursor:pointer;

}
.slider-btn-left{
  left:10px;
 background-color: transparent;
}
.slider-btn-right{
  right:10px;
   background-color: transparent;
}
.slider-icon:hover{
    border-color:rgb(97, 97, 97)
}
.slider-icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left:3px solid rgb(255, 255, 255);
    border-bottom:3px solid rgb(255, 255, 255);
    transition:border 2s;
}
.slider-icon-left{
  transform:rotate(45deg);
}
.slider-icon-right{
    transform:rotate(-135deg)
}
// ------left----------

.left-in-enter{
    left: -100%;
}
.left-in-enter-active,.left-in-leave-active{
   transition: left 2s;
}
.left-in-enter-to,.left-in-leave{
     left: 0%;
}
.left-in-leave-to{
     left:100%;
}




// ------left----------
.right-arrow{
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// --------------banner----------




.additional-bar {
  padding: 15px;
}
.gh-btn {
  background-color: #232323;
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  white-space: nowrap;
  cursor: pointer;
}
.image-container{
  margin-bottom: auto;
  background-color: aqua;
  
}

.gdlr-core-title-item-title{
    animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.gdlr-core-title-item-caption{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.about-future-vision{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.gdlr-core-text-box-item-content{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}




.flex-col{
  flex-direction:column
}
.md\:flex{
  display:flex;
}
.grid-cols-1{
  grid-template-columns:repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
  grid-template-columns:repeat(2, minmax(0, 1fr));
}
.md\:grid-cols-5{
  // grid-template-columns:repeat(5, minmax(0, 1fr));
  grid-template-columns:repeat(2, minmax(0, 1fr));
}
.gap-4{
  gap:1rem;
}
.grid-cols-1{
  grid-template-columns:repeat(1, minmax(0, 1fr));
}
.relative{
  position:relative
}
.grid{
  display: grid;
}
.mb-8{
  margin-bottom:2rem;
}
.rounded-xl{
  border-radius:0.75rem;
}
.img{
  // display:block;
  vertical-align: middle;
}
.img{
  max-width:100%;
  // height:auto;
}
.md\:hidden{
  display:grid;
}
.hidden{
  display: none;
}



.preview {
  margin: 2rem auto 0 auto;
  width: 100%;
  height:auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/87db0364-e198-4abb-b4e9-d6c3c70ebd68/RFNDXzMyNjQuanBlZy0xNjMzMTY4NDQzNzYwLTY5NjE=.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  object-fit: cover;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
  // animation:bottomIn 1s linear 0s;
}
// @keyframes bottomIn {
//     from {
//         transform: translate3d(30%, 0%, 0);
//     }

//     to {
//         transform: none;
//     }
// }

.preview2 {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a1a593a6-c6ba-4c8f-8663-1ee9d72252d3/Q29weSBvZiBEb21lX0EuanBnLTE2MzMxNzEyMDA3MDctMzE0Mw==.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  align-items: center;
  overflow: hidden;
  // animation:bottomIn 2s linear 0s;
}

.preview3 {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a80d9658-dbf7-45be-bb50-9642899f6212/56ys5LqM57WELmpwZy0xNjMzMTY4NzM5OTI1LTU1Mzc=.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  align-items: center;
  overflow: hidden;
  // animation:bottomIn 3s linear 0s;
}
.preview__text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.preview__text__link__btn{
  background-color: #232323;
  border-radius: 30px;
  transition:all 0.3s linear;
  color: aliceblue;
 
}
.preview__text__link__btn:hover{
  background-color: rgb(236, 236, 236);
}
.default-btn{
  text-transform:uppercase;
  font-weight:600;
  font-size: 0.8rem;
  line-height:0.5rem;
  letter-spacing: 0.1rem;
  padding:0.7rem 0.7rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}
.default-btn:hover{
  color: #232323;
}
label {
  background: #CCC;
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;

}
 label:hover{
  background-color: #333;
 }
input[type=checkbox]:checked{
    label{
      background:black;
    }
}

// -------------slider2----------------
.slide{
    width: 100%;
    overflow: hidden;
}
.slide-list{
  display: flex;
  list-style-type: none;
  padding: 0;
}
.slide-article{
  background-color: #eee;
  padding-top: 10px;
  height: 100%;
  margin: 10px;
  border-radius: 10px;
}
.slide-ctrl{
  margin-top: 3rem;
 display: flex;
 align-items: center;
 justify-content: center;
}
.slide-prev,
.slide-next{
   width: 150px;
   display: inline-block;
   background-color: #c0c0c0;
   color: #fff;
   margin: 0 10px;
   padding:5px 15px;
   border-radius: 50px;
   cursor: pointer;
   &:hover{
    color: rgb(68, 68, 68);
   }

}
.square{
  width: 150px;
  height: 150px;
  background-color: coral;
}
.early-bird{
  width: 10em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.early-bird::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
}
.early-bird:hover::before{
  width: 10em;
}

// .early-bird:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }


.pre-sale{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.pre-sale::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.pre-sale:hover::before{
  width: 9em;
}
// .pre-sale:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }

.reqular-tickets{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position:relative;
}
.reqular-tickets::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.reqular-tickets:hover::before{
  width: 9em;
}
// .reqular-tickets:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.students-tickets{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.students-tickets::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.students-tickets:hover::before{
  width: 9em;
}
// .students-tickets:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.fvl_about{
  width:80vw;
  height:auto;
  margin: 0 auto;
  display: flex;
  padding:2rem;
  position: relative;
  z-index: 1;
}
.fvl_icon{
  width:40%;
  height:100%;
  margin-right:2rem;
}
.eyes{
  width:10rem;
   height:6rem;
   background-color:black;
   position: relative;
   overflow: hidden;
}
.eye_ball{
  width:80%;
  height: 100%;
  background-color:white;
  border-radius:100% 0;
  transform:rotate(32deg);
  position: absolute;
  left:20%;top:43%;
  overflow: hidden;
  border: 2px black solid;
}
.ball{
  width:60%;
  height: 50%; 
  border: 20px solid black;
  border-radius:50%;
  position: absolute;
  top:30%;
  left: 30%;
  transform: rotate(-30deg);
}
.reflect{
  width: 15px;
  height: 15px;
  border-radius:50%;
  background-color:white;
  position: absolute;
  top: -400%;
  left: 60%;
}
.about_fvl{
  color: white;
  width: 60%;
  height: 100%;
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1em;
}
.fvl_tickets{
  width: 100vw;
   height:auto;
   margin-top: 4rem;
   position:relative;
}
.exibition_info{
  width: 80%;
  height:auto;
  margin: 0 auto;
  display: flex;
}
.sales_info{
  width: 40%;
  height: auto;
  z-index: 2;
  margin-top: 2rem;
}
.title_background{
  width:8rem;
  height:auto;
  border-radius: 30px;
  background-color: black;
  color: aliceblue;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.about_event{
  margin-top: 3rem;
  width: 100%;
  height: 100%;
}
.tickets{
  width: 60%;
  height: auto;
  position: relative;
  z-index: 2;
  background: white;
  padding: 1em;
  border-radius: 25px;
}
.ticket_buttons{
  top: 0;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin:0.5rem;
}



.tickets_info{
  width:100%;
  height: auto;
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
   display: flex;
   flex-direction:column;
   margin-top: 2rem;
   padding: 1rem;
}

.ticket-price-animate{
  animation: price-enter 0.5s linear;
}
.ticket-time-animate{
  animation: time-enter 0.5s linear;
}
.sales-enter-animate{
  animation: sales-enter 0.5s linear;
}
//////ticket-info-enter-animation
.early-bird-price{
  animation: enter-in 1s linear;
}
.pre-sale-price{
  animation: enter-in 1s linear;
}
.regular-price{
  animation: enter-in 1s linear;
}
.student-price{
  animation: enter-in 1s linear;
}
.early-bird-time{
  animation: enter-in 1s linear;
}
.pre-sale-time{
  animation: enter-in 1s linear;
}
.regular-time{
  animation: enter-in 1s linear;
}
.student-time{
  animation: enter-in 1s linear;
}
@keyframes enter-in {
  0% {opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes price-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes time-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes sales-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
//////

.ticket-price{
  display: flex;
  flex-direction:row;
  width:100%;
}
.ticket-time{
display: flex;
flex-direction:row;
width:100%;
}
.sales-info{
  display: flex;
  flex-direction:row;
  width:100%;
}
.notice{
  font-size:0.8rem;
  font-weight:lighter;
  color: black;
  margin-top: 1rem;
  line-height:1.5;
  letter-spacing: .5px;
}
.background2{
  width: 100vw;
  height:100vh;
}
.program-list{
  width:80%;
  height:auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;
}
.grid_title{
  width: 80%;
  height: auto;
  position: relative;
  margin: 0 auto;
  margin-top:3rem;
}
.container{
  width: 80%;
  height: auto;
  position: relative;
  margin:0 auto;
}
.faq-container{
  width:90%;
  height:auto;
  text-align: center;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding:2rem;
  margin-top: 1rem;
}
.box{
  width: 80%;
}
.content{
  width: 100%;
  height: auto;
  text-align: left;
  font-size:1.5rem;
  font-weight: bolder;
}

.row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    padding: 1rem 1rem;
}

.card{
  position: relative;
  overflow: hidden;
  display: flex;
  background: #ffffff;
  width:250px;
  height:250px;
  margin:10px;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.imagewrap{
  width:100%;
  height:100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.imagewrap img{
  transition:transform 0.8s;
}
.imagewrap-background{
  position: absolute;
  background-color:black;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}
.card:hover{
  //  .imagewrap{
  //     height: 80%;
  //  }
  .imagewrap-background{
  opacity: 0.5;
  }
  .wordwrap{
    transition: 1s ease-in-out;
    opacity: 1;
    color: white !important;
    position: absolute;
    z-index: 999;
  }
  .imagewrap img{
    transform: scale(1.2);
  }
}

.mb-80{
   margin-bottom:5px;
}
.wordwrap{
  width:100%;
  height:20%;
  opacity: 0;
  padding:2px;
  position: absolute;
  top:40%;
  color: rgb(255, 255, 255) !important;
  font-weight: bolder;
  text-align: center;
  display: flex;
  font-size:1rem;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
// .wordwrap:hover{
//    font-size: 1rem;
// }
.wordwrap:hover{
  color: rgb(255, 255, 255) !important;
}

.card:after{
  content: '';
    width: 0%;
    height: 1px;
    border: 1.5px rgb(0, 255, 170) solid;
    background-color: #000;
    position: absolute;
    bottom:0;
    left: 0;
    transition: all .3s linear;
    z-index: 999;
}

.card:hover::after{
   width: 100%;
}


.shut{
  width:200px;
  height:80px;
  transform: rotate(-30deg);
  position: absolute;
  border-radius:40%;
  background-color: #000;
  top: -65%;
  left:-50%;
  z-index: 999;
  transition: 0.5s ease-in-out;
}

// .eyes:hover{
//   .shut{
//  top:-6%;
//   }
// }


summary{
   margin-bottom: 1rem;
   outline: none;
   position: relative;
   font-size: 1.2rem;
}

details >summary::before{
   position: absolute;
   right: 20px;
 
  }
  details{
   margin-bottom: 2rem;
   
  }

  details[open] summary ~ * {
  animation: sweep .5s ease-in-out ;
}


@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 0px}

}

.forwords{
  background-color: springgreen;
}

@media only screen and (max-width:768px){
.banner-box{
  height: 400px;
}
}
@media only screen and (max-width:720px) {
  .banner-box{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    // margin-top:4rem;
  }
  .coming{
    font-size:60px;
    top:30%;
    font-weight: bold
  }
  .coming_img{
  position: absolute;
  bottom:-10px;
  width: 1280px;
  right: -2rem;
  height: auto;
  // filter: blur(10px);
}
  .banner-back{
    height:80%;
    overflow: hidden;
    max-width: 100%;
  }
  .fvl-logo-img{
    width: 80%;
    top:20%;
  }
  .ticket_buttons{
    flex-direction: column;
  }
  .fvl_about{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100% !important;
    height: auto;
    padding:1rem;
  }
  .fvl_icon{
    margin-bottom: 2rem;
  }
  .early-bird{
    width: 10em;
    height:4em;
  }
  .early-bird:hover::before{
    width:10em;
  }
  .pre-sale{
    width: 10em;
    height:4em;
  }
  .pre-sale:hover::before{
    width:10em;
  }
  .reqular-tickets{
    width: 10em;
    height:4em;
  }
  .reqular-tickets:hover::before{
    width:10em;
  }
  .students-tickets{
    width: 10em;
    height:4em;
  }
  .students-tickets:hover::before{
    width:10em;
  }
  .about_fvl{
    width: 100% !important;;
    height:100%;
  }
  .early-bird-price{
    margin-left: 1rem;
  }
  .early-bird-time{
    margin-left: 1rem;
  }
  .pre-sale-time{
    margin-left: 1rem;
  }
  .regular-time{
   margin-left:1rem

  }
  .student-time{
    margin-left: 1rem;
  }
  .sales-detail{
    font-size: 0.8rem;
    margin-left: 1rem;
  }
  .background2{
    width: 100vw;
    height: auto;
  }
  .mb-80{
    margin-bottom: 30px !important
  }
  .container{
    width: 100%;
  }
  .card{
    width: 100%;
  }
  .imagewrap{
    width: 100%;
  }
  .faq-container{
    width: 100%;
    padding:0.5rem;
  }
}




// .early-bird.clicker{
// .ticket-price{
//   animation: test 5s linear;
// }
// @keyframes test{
//   0% {opacity: 0;}

//   100%{opacity:1}
// }

// }






// -------------slider2----------------


</style>

