<template>

<div id="app" style="scroll-behavior: smooth;">

<head>
    <meta charset="UTF-8">
    <meta name = "viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie-edge">
</head>


       <div class="filter gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align clearfix" style="padding-top:90px;">
          <ul style="margin-left: 0;border:1px gray solid;border-radius:30px;padding: 15px;">
            <li><a href="#" class="active" data-filter="*" @click="type='All'">All</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class1" @click="type='artist'">藝術家</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class1" @click="type='2018'">2018</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class2" @click="type='2019'">2019</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class3" @click="type='2020'">2020</a> <span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class1"@click="type='2021'">2021</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class5"@click="type='2022'">2022</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6"@click="type='2023'">2023</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6"@click="type='2024'">2024</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6">徵件作品</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class6">SAT</a><span class="kleanity-separater">/</span></li>
            <li><a href="#" data-filter=".class7">開場影像</a></li>
        </ul>
       </div>
<!--       
       <div class="contaier" style="max-width:1320px;margin: 0 auto;">
           <div class="gallery" style="position: relative;background-color:white;"> 
               <div class="row grid max-margin" style="display: flex;flex-wrap: wrap;flex-direction: row; justify-content: center;align-items: center;border:5px green solid">
                  <div class="col-md-6 card mb-80" v-for="(item, index) in products" style="margin-left:20px;margin-right:20px;">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                        <div class="info" style="display: block;padding-top:400px;position:absolute;">
                           <h3 class="title">{{ptoducts}}</h3>
                           <p class="desc"></p>
                        </div>
                 </div>
              </div>
          </div>
       </div> -->
       <Transition>

         <!-- <div class="artbox" style="width: 500px;height: 500px;background-color: blue;margin: 0 auto;"></div> -->
       <template v-if="type === 'All'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 2px red solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>

       <template v-if="type === 'artist'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px blue solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
       </template>

       <template v-if="type === '2018'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px green solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>

    <template v-if="type === '2019'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px yellow solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>


    <template v-if="type === '2020'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px purple solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>

    <template v-if="type === '2021'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px brown solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>

    <template v-if="type === '2022'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px gray solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>


    <template v-if="type === '2023'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px orange solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>

    <template v-if="type === '2024'">
       <div class="container">
               <!-- <div class="row grid max-margin" style="border:1px green solid;margin: 0 auto;"> -->
                <div class="row grid max-margin" style="margin: 0 auto;border: 5px white solid;">
                    <div class="col-md-6 card mb-80" v-for="(item, index) in products">
                       <div class="imagewrap">
                            <img :src ="item.img">
                        </div>
                     </div>
               </div>
       </div>
    </template>
    

    

    </Transition>
</div>

</template>


<script scope>
import axios from 'axios';
import '../google-fonts.css';
import '../kleanity-style-custom.css';
import '../page-builder.css';
import '../style-core.css';
import '../font-awesome.css';

export default {
  name: '2023',
  data () {
    return {
      msg: 'Welcom to FUTURE VISION LAB',
      show:true,
      type:'2023',
      products:[],
      productData : {
       
      },
    }
  },
  mounted(){
        axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules?limit=25').then((response)=>{
        this.products = response.data
        console.log(response.data)}).catch((error) => console.log(error))
  },

}



</script>
<style lang="scss" scoped>

.gallery{
   position: relative;
   height: 5280px;
}
.card {
   background: #eee;
   width:400px;
   height:400px;
   margin-left: 30px;
   margin-right: 30px;
   display: inline-block;
   justify-content: center;
   align-items: center;
}
.mb-80{
   margin-bottom: 80px !important;
}
.col-md-6{
    flex:0  0 auto;

}
.row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.text-center{
    text-align: center !important;
}

</style>

