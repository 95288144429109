<template>
   <section>
    <head>
   <title>Fvl 2024</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width">

    </head>
        <section class="home" style="background-color:white;">
       
               <div class="banner-box">
                <div class="banner" >
                <img src="../assets/2024banner.png" style="position: absolute;bottom: 0;">
                <div class="banner-back" style="filter:blur(30px);transform: scale(1.2);">
                <banner-background></banner-background>
                </div>
                </div>
            
              </div>

          

                <!-- <div class="fvl-logo"> -->
                  <!-- <img class="fvl-logo-img" src="../assets/FVL.png"> -->
                  <!-- </div> -->



                  <!-- ///origin-slider -->
                  <!-- <TransitionGroup tag="div" class="banner" :name="transitionName">
                     <div class="page" v-for="(img,index) of imgs":key='index' v-show="index === show">
                         <template v-if="img.type === 'img'">
                       <img :src="img.src" style="height: 100%;height:100%;width:100%;object-fit: cover;" >
                       </template>

                       <template v-else-if="img.type === 'video'">
                         <iframe :src="img.src"
                          allowfullscreen=""
                          allow="autoplay" 
                          width="100%" height="100%"></iframe>
                        </template>
                     </div>    
                  </TransitionGroup> -->
                  <!-- ///origin-slider -->


                  <!-- <canvas> -->
                    <!-- <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div>
                    <div class="buble"></div> -->
                  <!-- </canvas> -->
 
              <div class="about-background" style=" background:blue;position: relative;">
       <div class="fvl_about">
              <div class="fvl_icon">
                  <div class="eyes">
                    <div class="eye_ball">
                       <span class="shut" id="shut"></span>
                          <div class="ball" >
                            <div class="reflect">
                            </div>
                          </div>
                   </div>
                 </div>
                      <!-- <p style="font-size:1.5rem;font-weight:bolder;margin-top: 0.5rem;">FUTURE <br>VISION <br>LAB</p> -->
               </div>
                <div class="about_fvl">
                    <p>Lorem ipsum dolor sit amet consectetur. Cras laoreet duis nunc mauris facilisi elit mus. 
                  Quis eros nec potenti volutpat ullamcorper bibendum quam et. 
                  Tellus aliquet pulvinar gravida turpis. Orci integer placerat lobortis nec. Sed quisque est pulvinar placerat ac consectetur. 
                  Sed aenean felis eleifend neque mattis urna ultrices. Amet ipsum laoreet sed scelerisque vel. Vestibulum sollicitudin elit sit fringilla commodo. 
                    Sagittis duis fusce bibendum habitant aliquet viverraLorem ipsum dolor sit amet consectetur. Cras laoreet duis nunc mauris facilisi elit mus. 
                  Quis eros nec potenti volutpat ullamcorper bibendum quam et. 
                  Tellus aliquet pulvinar gravida turpis. Orci integer placerat lobortis nec. Sed quisque est pulvinar placerat ac consectetur. 
                  Sed aenean felis eleifend neque mattis urna ultrices. Amet ipsum laoreet sed scelerisque vel. Vestibulum sollicitudin elit sit fringilla commodo. 
                    Sagittis duis fusce bibendum habitant aliquet viverra.</p>
                </div>
       </div>

       <div class="fvl_tickets">
           <div class="exibition_info">
                <div class="sales_info">
                  <div class="title_background">
                    <p style="padding: 5px;margin-bottom:0;font-size: 1.5rem;">購票資訊</p>
                  </div>
                    
                    <div class="about_event" >
                      <div class="event_date" style="display: flex;flex-direction:column;flex-wrap: wrap;">
                      <p style="font-size:1rem;font-weight:bolder;color: black;">活動日期</p>
                     <div class="date" style="display: flex;flex-direction: row;flex-wrap: wrap;">
                      <p style="color: black;">9.30(一)</p>
                      <p  style="color: black;"> ——12.30(一)</p>
                      </div>
                      </div>
                      <br>
                      <p style="font-size:1rem;font-weight:bolder;color: black;">地點</p>
                      <p style="color: black;">C-LAB東草坪</p>
                      <br>
                      <p style="font-size:1rem;font-weight:bolder;color: black;">HashTag</p>
                      <p style="color: black;">#音像實驗 </p>
                      <p style="color: black;">#科技媒體 </p>
                      <p style="color: black;">#futurevisionlab </p>
                    </div>
                </div>

                <div class="tickets">

                    <div class="ticket_buttons">
                        <button type="button" class="early-bird" :class="{'active':link == 'early-bird'}" v-on:click.prevent="link = 'early-bird'"><p style="font-size: 1rem;">早鳥團體票</p></button>
                        <button type="button" class="pre-sale" :class="{'active':link == 'pre-sale'}" v-on:click.prevent="link = 'pre-sale'" @click="plus()"><p style="font-size: 1rem;">預售票</p></button>
                        <button type="button" class="reqular-tickets" :class="{'active':link == 'regular'}" v-on:click.prevent="link = 'regular'"><p style="font-size: 1rem;">展期票</p></button>
                        <button type="button" class="students-tickets" :class="{'active':link == 'student'}" v-on:click.prevent="link = 'student'"><p style="font-size: 1rem;">學生票</p></button>
                    </div>


                    <div class="tickets_info" >
                         <div v-if="link == 'early-bird'" class="ticket-price" >
                            <div class=" early-bird-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                               <div class="ticket-price-animate" style="width: 30%;">
                                    <p style="font-size:1rem;font-weight:bolder;color: black;">票價</p> 
                               </div>
                               <div class="early-bird-price" style="width:70%;">
                                 <p style="width:70%;left: 0;">$200</p>
                               </div>
                           </div>
                         </div>


                         <div v-else-if="link == 'pre-sale'" class="ticket-price">
                          <div class=" pre-sale-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                               <div class="ticket-price-animate" style="width: 30%;">
                                <p style="font-size:1rem;font-weight:bolder;color: black;width:30%">票價</p> 
                               </div>
                               <div class="pre-sale-price" style="width:70%;">
                                    <p style="width:70%;left: 0;">$220</p>
                               </div>
                          </div>
                        </div> 

                         <div v-else-if="link == 'regular'" class="ticket-price" >
                          <div class=" regular-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                            <div class="ticket-price-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;width:30%">票價</p> 
                            </div>
                            <div class="pre-sale-price" style="width:70%;">
                            <p style="width:70%;left: 0;">$250</p>
                            </div>
                            </div>
                         </div>
                         <div v-else-if="link == 'student'" class="ticket-price">
                          <div class=" studente-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                            <div class="ticket-price-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;width:30%">票價</p> 
                            </div>
                            <div class="pre-sale-price" style="width:70%;">
                            <p style="width:70%;left: 0;">$200</p>
                            </div>
                            </div>
                         </div>

                         <hr style='background-color:black; height:0.5px; border:none;opacity: 0.5;'>

                          <div class="ticket-time">
        
                            <div v-if="link == 'early-bird'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                               <div class="ticket-time-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;">售票時間</p>
                            </div>
                              <div class="early-bird-time" style="width:70%;">
                                <p>2024.7.26 - 2024.8.24</p>
                              </div>
                            </div>

                            <div v-else-if="link == 'pre-sale'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                               <div class="ticket-time-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;">售票時間</p>
                            </div>
                              <div class="pre-sale-time"  style="width:70%;">
                                <p>2024.7.26 - 2024.8.24</p>
                              </div>
                            </div>

                            <div v-else-if="link == 'regular'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                               <div class="ticket-time-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;">售票時間</p>
                            </div>
                              <div class="regular-time" style="width:70%;">
                                <p>2024.7.26 - 2024.8.24</p>
                              </div>
                            </div>

                            <div v-else-if="link == 'student'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                               <div class="ticket-time-animate" style="width: 30%;">
                            <p style="font-size:1rem;font-weight:bolder;color: black;">售票時間</p>
                            </div>
                              <div class="student-time" style="width:70%;">
                                <p>2024.7.26 - 2024.8.24</p>
                              </div>
                            </div>

                            
                            
                            <!-- <div v-else-if="link == 'pre-sale'" style="width:70%;left: 0;"><div class="pre-sale-time"><p>2024.7.28 - 2024.9.15</p></div></div> -->
                            <!-- <div v-else-if="link == 'regular'" style="width:70%;left: 0;"><div class="regular-time"><p>2024.8.28 - 2024.10.15</p></div></div> -->
                            <!-- <div v-else-if="link == 'student'" style="width:70%;left: 0;"><div class="student-time"><p>2024.6.28 - 2024.11.15</p></div></div> -->
                          </div>
                          <hr style='background-color:black; height:0.5px; border:none;opacity: 0.5;'>
  
                          <div v-if="link == 'early-bird'" >
                            <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                               <div class="sales-enter-animate" style="width:30% ;">
                                   <p style="font-size:1rem;font-weight:bolder;color: black;">購票資訊</p>
                               </div>
                               <div class="sales-detail" style="width: 70%;">
                                  <p style="left:0;">電子票券請至 ibon售票系統網站購買</p>
                               </div>
                            </div>
                          </div>

                          <div v-if="link == 'pre-sale'" >
                            <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                               <div class="sales-enter-animate" style="width:30% ;">
                                   <p style="font-size:1rem;font-weight:bolder;color: black;">購票資訊</p>
                               </div>
                               <div class="sales-detail" style="width: 70%;">
                                  <p style="left:0;">電子票券請至 ibon售票系統網站購買</p>
                               </div>
                            </div>
                          </div>

                          <div v-if="link == 'regular'" >
                            <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                               <div class="sales-enter-animate" style="width:30% ;">
                                   <p style="font-size:1rem;font-weight:bolder;color: black;">購票資訊</p>
                               </div>
                               <div class="sales-detail" style="width: 70%;">
                                  <p style="left:0;">電子票券請至 ibon售票系統網站購買</p>
                               </div>
                            </div>
                          </div>

                          <div v-if="link == 'student'" >
                            <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                               <div class="sales-enter-animate" style="width:30% ;">
                                   <p style="font-size:1rem;font-weight:bolder;color: black;">購票資訊</p>
                               </div>
                               <div class="sales-detail" style="width: 70%;">
                                  <p style="left:0;">電子票券請至 ibon售票系統網站購買</p>
                               </div>
                            </div>
                          </div>

                         <hr style='background-color:black;height:0.1px; border:none;opacity: 0.5;'>

                           <div class="notice">
                            <p style="font-size:1rem;font-weight:bolder;color: black;width:100%;padding:0;margin-bottom:1rem;">注意事項</p>
                           <p><b>◉</b>   演出內容有部分包含強烈閃光，敬請斟酌入場。<br>
                            <b>◉</b>   因活動場地為鏡面地板，建議穿著「褲裝」進場觀賞。<br>
                            <b>◉</b>    因場內空間有限，禁止鋪設野餐墊、椅子，若有其他需求，請洽前台工作人員協助。<br>
                            <b>◉</b>   播映過程可拍照、即時動態拍攝，但禁止使用閃光燈。<br>
                            <b>◉</b>   主辦單位保有調整與變更活動之權利。</p>
                           </div>
                    </div>
                </div>

                <!-- <div class="bg-test" style="width:100vw;height:100%;background-image:url(../imgs/test.jpg);   background-size:contain; background-repeat: no-repeat;  background-position:right bottom;position: absolute;z-index:0;top: 0;left: 0;opacity: 0.1;"></div> -->
                  <!-- <div class="bg" style="width:100%;height:100%;background-position: right bottom;position: absolute;z-index: 0;top: 0;left: 0;opacity: 0.1;background-color: aqua;">
                   <ani-back></ani-back>
                  </div> -->
             </div>
           </div>
           <div class="background2" style="width: 100%;height:100%;position: absolute;top:0;overflow: hidden;">
              <!-- <back-ground></back-ground> -->
              <!-- <back-ground></back-ground> -->
          </div>
           </div>
<!-- 
           <div class="banner-back" style="filter:blur(30px);transform: scale(1.2);">
                <banner-background></banner-background>
                </div> -->
      
           
                    <!-- <div class="previewbox" style="display: flex;flex-direction: column;">
                             <article class="preview" style="width:80%;height:auto;">
                             
                                   <div class="preview__thumbnail"></div>
                                     <div class="preview__text">
                                        <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                           <p class="preview__text__description" style="color: white;"> Upgrade to premium speakers that are phenomenally built to deliver trulyremarkable sound.</p>
                                            <button class="preview__text__link__btn default-btn">Read More</button>
                                      
                                     </div>
             
                              </article>


                          <article class="preview2"  style="width:80%;height:auto;">
                            <div class="preview__thumbnail"></div>
                               <div class="preview__text">
                                  <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                       <p class="preview__text__description" style="color: white;">
                                          Upgrade to premium speakers that are phenomenally built to deliver truly
                                          remarkable sound.
                                      </p>
                                   <router-link to="2018" class="preview__text__link">
                                       <button class="preview__text__link__btn default-btn">
                                          Read More
                                       </button>
                                   </router-link>
                               </div>
                           </article>


                           <article class="preview3"  style="width:80%;height:auto;">
                              <div class="preview__thumbnail"></div>
                                  <div class="preview__text">
                                     <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                        <p class="preview__text__description" style="color: white;">
                                          Upgrade to premium speakers that are phenomenally built to deliver truly
                                          remarkable sound.
                                        </p>
                                      <router-link to="2018" class="preview__text__link">
                                           <button class="preview__text__link__btn default-btn">
                                             Read More 
                                           </button>
                                      </router-link>
                                   </div>
                           </article>

                       </div> -->
                       <div class="program-list" >
                        <div class="title_background" >
                    <p style="padding: 5px;margin-bottom:0;font-size: 1.5rem;">節目場次</p>
                  </div>
                  <img src="imgs/list.jpg" style="margin-top: 3rem;">
                   </div>
           <div class="grid_title">
                          <div class="title_background">
                            <p style="padding: 5px;margin-bottom:0;font-size: 1.5rem;">播映作品</p>
                        </div>
            </div>
                        <div class="container" >
                           <!-- <div class="row grid max-margin" style="margin: 0 auto;border: 5px orange solid;"> -->
                            <div class="row grid max-margin" style="margin: 0 auto;justify-content: center;position: relative;">
                                <!-- <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="index" v-on:click="chosework(index)"> -->
                                  <!-- <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="item.id" v-on:click="jump(item.id)">  -->
                                    <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="item.id">
                                   <div class="imagewrap">
                                         <div class="imagewrap-background"></div>
                                            <img :src ="'https://unzip-clab-api.clab.org.tw/'+item.photo_1" style="height:100%;width: 100%;object-fit: cover; z-index: 2;">
                                   </div>
                               
                                   
                                       <div class="wordwrap">
                                        <!-- <router-link :to="`FVLhome/Aboutwork/${index}/${item.title_zh}/${item.introduction_zh}`"> -->
                                          <router-link :to="{
                                             name:'Aboutwork',
                                             params:{
                                              id:index,
                                              title:item.work_zh.title,
                                              word:item.work_zh.proposal,
                                              photo:item.photo_1,
                                              photo2:item.photo_2,
                                              photo3:item.photo_3,
                                              // artist:products2,
                                              // artist:item.groups.artists
                                              // artist:this.products2.item.work_zh.note,
                                             }
                                          }">
                                      <p style="color: aliceblue;" > {{ item.work_zh.title }}</p>
                                        </router-link>
                                       </div>
                                   
                                </div>
                            </div>
                         </div>


                         <div class="grid_title">
                            <div class="title_background">
                                <p style="padding: 5px;margin-bottom:0;font-size: 1.5rem;">FAQ</p>
                            </div>
                        </div>




                         <div class="faq-container">
                              <div class="box">
                              <div class="content">
                                  <details id="qa1">
                                     <summary>WHERE IS FUTURE VISION LAB?<hr></summary>
                                  
                                          <div class="answer" style="font-size: medium;">
                                            <p style="text-align: left;">Lorem ipsum dolor sit amet consectetur. 
                                              Tincidunt id dolor morbi at ipsum. Vitae tellus felis sem aliquet. Ac.</p>
                                          </div>
                                  </details>
                                  <details id="qa2">
                                     <summary>CAN PHOTO BE TAEKN DURING THE SHOW?<hr></summary>
                                  
                                          <div class="answer" style="font-size: medium;">
                                            <p>Lorem ipsum dolor sit amet consectetur. 
                                              Tincidunt id dolor morbi at ipsum. Vitae tellus felis sem aliquet. Ac.</p>
                                          </div>
                                  </details>
                                  <details id="qa3">
                                     <summary>CAN I SIT ON THE GROUND? <hr></summary>
                                  
                                          <div class="answer" style="font-size: medium;">
                                            <p>Lorem ipsum dolor sit amet consectetur. 
                                              Tincidunt id dolor morbi at ipsum. Vitae tellus felis sem aliquet. Ac.</p>
                                          </div>
                                  </details>
                                  <details id="qa4">
                                     <summary>HOW LONG IS THE SHOW?<hr></summary>
                                  
                                          <div class="answer" style="font-size: medium;">
                                            <p>Lorem ipsum dolor sit amet consectetur. 
                                              Tincidunt id dolor morbi at ipsum. Vitae tellus felis sem aliquet. Ac.</p>
                                          </div>
                                  </details>

                              </div>
                              
                              </div>
      
                         </div>
                         <div class="border-line">
                       <hr style="color: aliceblue;display: block;margin-top:2rem;">
                      </div>

        <!-- <div class="bottom-ul" style="margin-top: 4rem;display:flex;position: relative;flex-direction: column;justify-content:flex-start;width: 100%;margin-bottom: 4rem;">
          <ul  style="display: flex;width: 100%; justify-content: space-evenly;">
            <li><a href="https://vuejs.org/v2/guide/">DOME 1.0</a></li>
            <li><a href="https://bulma.io/documentation/overview/start/">DOME 2.0</a></li>
            <li><a href="https://buefy.github.io/#/documentation/start">沈浸式場域</a></li>
            <li><a href="https://open-call.clab.org.tw/brd/fvl-2024/apply">徵件系統</a></li>
          </ul>
         </div> -->
         <info-bar></info-bar>
        <foot-bar></foot-bar>
  </section>

</section>
</template>

<script>
// import "../jquery.js";
import axios from 'axios';
import '../parallaxedEffect.js';
import {ThreeJs} from '../components';
import { FootBar } from '../components';
import { InfoBar } from '../components';
import {gsap} from "gsap";
import{Anibackground}from '../components';
import { BannerBackground } from '../components';
import {Background} from '../components';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { TextPlugin } from 'gsap/dist/TextPlugin.js';
// import { BooleanKeyframeTrack, LineLoop, LoopRepeat, RedFormat } from 'three';
import { defineComponent } from 'vue'

let timer 
const interval = 4000;


// import 'font-awesome/css/font-awesome.min.css';
// import 'font-awesome/css/font-awesome.css';
// import 'font-awesome/fonts/fontawesome-webfont.eot';
// import 'font-awesome/fonts/fontawesome-webfont.svg';
// import 'font-awesome/fonts/fontawesome-webfont.ttf';
export default defineComponent({
  name: 'Fvlhome',
  components: {
     'three-js':ThreeJs,
     'foot-bar':FootBar,
     'info-bar':InfoBar,
     'ani-back':Anibackground,
     'banner-background':BannerBackground,
     'back-ground':Background,
  },
  
  
  data () {
    return {
      slideData:[],
      show:true,
      type:'All',
      products:[],
      products2:[],
      productData : {
      },
      banner:"../assets/2024banner.png",
      link:'early-bird',
      // tickets:[
      // {early:'$200'},
      //  {pre:'$180'},
      //  {normal:'$220'},
      //  {student:'$195'}
      // ],
      transitionName:'left-in',
      show:0,
      msg: 'A quick-start vue.js frontend development template.',
      imgs:[
        {type:'img',src:'./imgs/1.jpeg'},
        // {type:'img',src:'./imgs/2.jpeg'},
        // {type:'img',src:'./imgs/3.jpeg'},
        // {type:'video',src:"https://www.youtube.com/embed/videoseries?mute=1&autoplay=1;si=fCFjJb6JtgHHtucM&amp;list=PLXJ_MjvcL-q5V-vae8rmre2Rz4ZTjB6gF"},
        // {type:'video',src:"https://www.youtube.com/embed/yM-Q5qNNVbM?mute=1&autoplay=1;si=yX0PfbGKm9-szmuE" }
      ],
    }
  },

  

  methods:{
    // jump(id){
    //   this.$router.push(
    //     { name:item.link,
    //      query:{id}}
    //      );
    // },
    chosework(index){
          // console.log(item);
          // for (var i = 0; i < this.products.length; i++) {
          // console.log(i);
          console.log(index)

        // }
          
    },
     
    plus(){
   console.log('已選擇預售票');

    },

    setShow(index){
        this.show = index 
      },
      nextShow(){
        this.show ++
      },

      slideCtrl(slidesToShow = 1){
        if(slidesToShow > 0 ){
          const shiftItem = this.slideData.shift();
          this.slideData.push(shiftItem);
          return;
        }
        if(slidesToShow <0){
          const shiftItem = this.slideData.pop();
          this.slideData.unshift(shiftItem);
        }
      },
       
},
  watch:{
    show(nVal,oVal){

          if(nVal<0){
        
          this.show = this.imgs.length -1 
          }else if(nVal >this.imgs.length -1){
     
            this.show = 0
          }else{
            if(oVal < 0 )this.transitionName = 'left-in'
            else if (oVal > this.imgs.length -1 )this.transitionName = 'right-in'
            else  this.transitionName = nVal>oVal ?'right-in' : 'left-in'
          }
    }
  },
   
  
onMounted(){
  parallaxie('.back-image.parallaxie');
},
  mounted(){
    console.log(this.$route)

    // const coords ={x:0,y:0};
    // const bubles = document.querySelectorAll(".buble");

    // bubles.forEach(function(buble){
    //   buble.x = 0;
    //   buble.y = 0;
    // })


    // window.addEventListener("mousemove",function(e){
    // coords.x = e.pageX;
    // coords.y = e.pageY;
    // // console.log(coords); 

    // gsap.to(bubles,{
    //   '--x':`${x}%`,
    //   '--y':`${y}%`,
    //   duration:0.3,
    //   ease:'sine.out'
    // })
    // });


    


    // function animateBubles(){

    //   let x = coords.x;
    //   let y = coords.y;

    // bubles.forEach(function(buble,index){
    //   buble.style.left = x -12 +"px";
    //   buble.style.top = y -12 +"px";
    //   buble.x = x;
    //   buble.y = y;
    //   buble.style.scale = (20-index)/20;

    //   const nextBuble = bubles[index + 1] || bubles[0];
    //   x += (nextBuble.x - x) * 0.3;
    //   y += (nextBuble.y - y) * 0.3;
    // });

    // requestAnimationFrame(animateBubles);
    // }

    // animateBubles();


// ----------自動輪播----------------
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

// const shut = document.getElementsById('shut');

gsap.to('#shut',{
     top:-6,
     duration:0.01,
     scrollTrigger:{
      trigger:'.fvl_icon',
      start:"top 50%",
      end:"top 30%",
      toggleActions:'play reverse restart reverse',
      markers:{
        startColor:"orange",
         endColor:"blue",
        fontSize:"2rem"
      }
    }
}),


   
timer = setInterval(this.nextShow,interval),

axios.all([

  // axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works?search=Future%20Vision%20Lab&limit=25',{responseType:'json'}).then((response)=>{
    axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'}),
        // console.log(response.data)}).catch((error) => console.log(error));
        // for(i=0;i<=this.products.lenght;i++){
        //   i=0;
        // }

    axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'})
    // this.artists = response.data.artist
    // console.log(response.data)}).catch((error) => console.log(error));
    ]).then(axios.spread((response1,response2) => {
      this.products = response1.data.works;
      this.products2 = response2.data.artists;

      console.log(this.products);

      console.log(this.products2);
    }))   .catch(err=>{
    console.log(err.response);
    });
  }

  



})

</script>

<style lang="scss" scoped>


.banner-box{
  height: 100vh;
  width:100vw;
  margin: 0 auto;
  position: relative;
  margin-top:4rem;
}
.banner{
  height: 100%;width:100%;object-fit: cover;overflow: hidden;z-index: 1;position: absolute;top: 0;
}
.banner-back{
  position: absolute;
  top: 0;
  z-index: -1;
}
.fvl-logo-img{
  position: absolute;
  top: 10%;
  z-index: 1;
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
}
// .banner{
//   position: relative;
//   width:100%;
//   // height:300px;
//   height: 100vh;
//   margin: 0 auto;
//   overflow:hidden;
//   object-fit: cover;
// }

// .buble{
//   z-index: 999;
//   background-color: #000000;
//   // pointer-events:none;
//   width:20px;
//   height:20px;
//   border-radius: 100px;
//   position: absolute;
//   pointer-events: none;
//   clip-path: circle(100px at var(--x,));
//   // transform: translate(-50%,-50%);
//   transition: 0.05s;
//   // border: px rgb(255, 255, 255) solid;
//   top:0;
//   left: 0;
//   // animation: colorgen 5s infinite;
// }

// @keyframes colorgen {
//   0% {
//     opacity: 1;
//     filter: hue-rotate(0deg);
//   }
//   100% {
//     opacity: 1;
//     filter: hue-rotate(360deg);
//   }
// }
// .banner-box:hover{
//   #{
// transform: scale(2,2);
// filter: blur(5px);
// opacity: 0.9;
// }
// }


@media only screen and (max-width:1024px) {
    .section {
       padding: 3rem 1rem;
    }
  }

  @media only screen and (min-width:1024px) {
    .section {
       padding: 3rem 0rem;
    }
  }

@media only screen and (min-width:850px) {
  .banner-box{
    margin: 0 auto;
    position: relative;
    height:100vh;
    overflow: hidden;
    width: 100vw;
    margin-top: 4rem;
  }
    .banner {
        // height:600px;
        height: 100%;
        width: 100%;
    }
    .banner.img{
      height: 100%;
      width: 100%;
    }
    .banner-back{
    position: absolute;
    height:100%;
    top: 0;
    z-index: -1;
  }
  }


  // @media screen and (max-width: 850px) {
  //   .banner-box{
  //     height:100%;
  //   }
  // }
  

.page{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height:100%;
  overflow-x: hidden;

}
.right-in-enter{
    left: 100%;
}
.right-in-enter-active,.right-in-leave-active{
   transition: left 0.5s;
}
.right-in-enter-to,.right-in-leave{
     left: 0%;
}
.right-in-leave-to{
     left:-100%;
}

// -----------------------------
.slider-btn{
  position:absolute;
  top:50%;
  opacity: 0.8;
  // z-index:999;
  height:10%;
  width: 50px;
  border: none;
  outline:0;
  cursor:pointer;

}
.slider-btn-left{
  left:10px;
 background-color: transparent;
}
.slider-btn-right{
  right:10px;
   background-color: transparent;
}
.slider-icon:hover{
    border-color:rgb(97, 97, 97)
}
.slider-icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left:3px solid rgb(255, 255, 255);
    border-bottom:3px solid rgb(255, 255, 255);
    transition:border 2s;
}
.slider-icon-left{
  transform:rotate(45deg);
}
.slider-icon-right{
    transform:rotate(-135deg)
}
// ------left----------

.left-in-enter{
    left: -100%;
}
.left-in-enter-active,.left-in-leave-active{
   transition: left 2s;
}
.left-in-enter-to,.left-in-leave{
     left: 0%;
}
.left-in-leave-to{
     left:100%;
}




// ------left----------
.right-arrow{
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// --------------banner----------




.additional-bar {
  padding: 15px;
}
.gh-btn {
  background-color: #232323;
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  white-space: nowrap;
  cursor: pointer;
}
.image-container{
  margin-bottom: auto;
  background-color: aqua;
  
}

.gdlr-core-title-item-title{
    animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.gdlr-core-title-item-caption{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.about-future-vision{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.gdlr-core-text-box-item-content{
  animation: fade 0.5s linear;
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}




.flex-col{
  flex-direction:column
}
.md\:flex{
  display:flex;
}
.grid-cols-1{
  grid-template-columns:repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
  grid-template-columns:repeat(2, minmax(0, 1fr));
}
.md\:grid-cols-5{
  // grid-template-columns:repeat(5, minmax(0, 1fr));
  grid-template-columns:repeat(2, minmax(0, 1fr));
}
.gap-4{
  gap:1rem;
}
.grid-cols-1{
  grid-template-columns:repeat(1, minmax(0, 1fr));
}
.relative{
  position:relative
}
.grid{
  display: grid;
}
.mb-8{
  margin-bottom:2rem;
}
.rounded-xl{
  border-radius:0.75rem;
}
.img{
  // display:block;
  vertical-align: middle;
}
.img{
  max-width:100%;
  // height:auto;
}
.md\:hidden{
  display:grid;
}
.hidden{
  display: none;
}



.preview {
  margin: 2rem auto 0 auto;
  width: 100%;
  height:auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/87db0364-e198-4abb-b4e9-d6c3c70ebd68/RFNDXzMyNjQuanBlZy0xNjMzMTY4NDQzNzYwLTY5NjE=.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  object-fit: cover;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
  // animation:bottomIn 1s linear 0s;
}
// @keyframes bottomIn {
//     from {
//         transform: translate3d(30%, 0%, 0);
//     }

//     to {
//         transform: none;
//     }
// }

.preview2 {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a1a593a6-c6ba-4c8f-8663-1ee9d72252d3/Q29weSBvZiBEb21lX0EuanBnLTE2MzMxNzEyMDA3MDctMzE0Mw==.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  align-items: center;
  overflow: hidden;
  // animation:bottomIn 2s linear 0s;
}

.preview3 {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: auto;
  background:transparent;
  background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a80d9658-dbf7-45be-bb50-9642899f6212/56ys5LqM57WELmpwZy0xNjMzMTY4NzM5OTI1LTU1Mzc=.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5.5rem 2.4rem;
  // border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  align-items: center;
  overflow: hidden;
  // animation:bottomIn 3s linear 0s;
}
.preview__text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.preview__text__link__btn{
  background-color: #232323;
  border-radius: 30px;
  transition:all 0.3s linear;
  color: aliceblue;
 
}
.preview__text__link__btn:hover{
  background-color: rgb(236, 236, 236);
}
.default-btn{
  text-transform:uppercase;
  font-weight:600;
  font-size: 0.8rem;
  line-height:0.5rem;
  letter-spacing: 0.1rem;
  padding:0.7rem 0.7rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}
.default-btn:hover{
  color: #232323;
}
label {
  background: #CCC;
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;

}
 label:hover{
  background-color: #333;
 }
input[type=checkbox]:checked{
    label{
      background:black;
    }
}

// -------------slider2----------------
.slide{
    width: 100%;
    overflow: hidden;
}
.slide-list{
  display: flex;
  list-style-type: none;
  padding: 0;
}
.slide-article{
  background-color: #eee;
  padding-top: 10px;
  height: 100%;
  margin: 10px;
  border-radius: 10px;
}
.slide-ctrl{
  margin-top: 3rem;
 display: flex;
 align-items: center;
 justify-content: center;
}
.slide-prev,
.slide-next{
   width: 150px;
   display: inline-block;
   background-color: #c0c0c0;
   color: #fff;
   margin: 0 10px;
   padding:5px 15px;
   border-radius: 50px;
   cursor: pointer;
   &:hover{
    color: rgb(68, 68, 68);
   }

}
.square{
  width: 150px;
  height: 150px;
  background-color: coral;
}
.early-bird{
  width: 10em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.early-bird::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
}
.early-bird:hover::before{
  width: 10em;
}

// .early-bird:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }


.pre-sale{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.pre-sale::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.pre-sale:hover::before{
  width: 9em;
}
// .pre-sale:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }

.reqular-tickets{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position:relative;
}
.reqular-tickets::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.reqular-tickets:hover::before{
  width: 9em;
}
// .reqular-tickets:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.students-tickets{
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30em;
  box-shadow: 6px 6px 12px #c5c5c5;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  background-color:transparent;
  border:none;
  padding:25px;
  margin:0.5rem;
  position: relative;
}
.students-tickets::before{
  content: '';
  width: 0;
  height:100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
  background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.students-tickets:hover::before{
  width: 9em;
}
// .students-tickets:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.fvl_about{
  width:80vw;
  height:auto;
  margin: 0 auto;
  display: flex;
  padding:2rem;
  position: relative;
  z-index: 1;
}
.fvl_icon{
  width:40%;
  height:100%;
  margin-right:2rem;
}
.eyes{
  width:10rem;
   height:6rem;
   background-color:black;
   position: relative;
   overflow: hidden;
}
.eye_ball{
  width:80%;
  height: 100%;
  background-color:white;
  border-radius:100% 0;
  transform:rotate(32deg);
  position: absolute;
  left:20%;top:43%;
  overflow: hidden;
  border: 2px black solid;
}
.ball{
  width:60%;
  height: 50%; 
  border: 20px solid black;
  border-radius:50%;
  position: absolute;
  top:30%;
  left: 30%;
  transform: rotate(-30deg);
}
.reflect{
  width: 15px;
  height: 15px;
  border-radius:50%;
  background-color:white;
  position: absolute;
  top: -400%;
  left: 60%;
}
.about_fvl{
  color: white;
  width: 60%;
  height: 100%;
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1em;
}
.fvl_tickets{
  width: 100vw;
   height:auto;
   margin-top: 4rem;
   position:relative;
}
.exibition_info{
  width: 80%;
  height:auto;
  margin: 0 auto;
  display: flex;
}
.sales_info{
  width: 40%;
  height: auto;
  z-index: 2;
  margin-top: 2rem;
}
.title_background{
  width:8rem;
  height:auto;
  border-radius: 30px;
  background-color: black;
  color: aliceblue;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.about_event{
  margin-top: 3rem;
  width: 100%;
  height: 100%;
}
.tickets{
  width: 60%;
  height: auto;
  position: relative;
  z-index: 2;
  background: white;
  padding: 1em;
  border-radius: 25px;
}
.ticket_buttons{
  top: 0;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin:0.5rem;
}



.tickets_info{
  width:100%;
  height: auto;
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
   display: flex;
   flex-direction:column;
   margin-top: 2rem;
   padding: 1rem;
}

.ticket-price-animate{
  animation: price-enter 0.5s linear;
}
.ticket-time-animate{
  animation: time-enter 0.5s linear;
}
.sales-enter-animate{
  animation: sales-enter 0.5s linear;
}
//////ticket-info-enter-animation
.early-bird-price{
  animation: enter-in 1s linear;
}
.pre-sale-price{
  animation: enter-in 1s linear;
}
.regular-price{
  animation: enter-in 1s linear;
}
.student-price{
  animation: enter-in 1s linear;
}
.early-bird-time{
  animation: enter-in 1s linear;
}
.pre-sale-time{
  animation: enter-in 1s linear;
}
.regular-time{
  animation: enter-in 1s linear;
}
.student-time{
  animation: enter-in 1s linear;
}
@keyframes enter-in {
  0% {opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes price-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes time-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
@keyframes sales-enter{
  0%{opacity: 0;margin-left: -2rem;}
  100%{opacity: 1;margin-left: 0;}
}
//////

.ticket-price{
  display: flex;
  flex-direction:row;
  width:100%;
}
.ticket-time{
display: flex;
flex-direction:row;
width:100%;
}
.sales-info{
  display: flex;
  flex-direction:row;
  width:100%;
}
.notice{
  font-size:0.8rem;
  font-weight:lighter;
  color: black;
  margin-top: 1rem;
  line-height:1.5;
  letter-spacing: .5px;
}
.background2{
  width: 100vw;
  height:100vh;
}
.program-list{
  width:80%;
  height:auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;
}
.grid_title{
  width: 80%;
  height: auto;
  position: relative;
  margin: 0 auto;
  margin-top:3rem;
}
.container{
  width: 80%;
  height: auto;
  position: relative;
  margin:0 auto;
}
.faq-container{
  width:90%;
  height:auto;
  text-align: center;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding:2rem;
  margin-top: 1rem;
}
.box{
  width: 80%;
}
.content{
  width: 100%;
  height: auto;
  text-align: left;
  font-size:1.5rem;
  font-weight: bolder;
}

.row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    padding: 1rem 1rem;
}

.card{
  position: relative;
  overflow: hidden;
  display: flex;
  background: #ffffff;
  width:250px;
  height:250px;
  margin:10px;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.imagewrap{
  width:100%;
  height:100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.imagewrap img{
  transition:transform 0.8s;
}
.imagewrap-background{
  position: absolute;
  background-color:black;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}
.card:hover{
  //  .imagewrap{
  //     height: 80%;
  //  }
  .imagewrap-background{
  opacity: 0.5;
  }
  .wordwrap{
    transition: 1s ease-in-out;
    opacity: 1;
    color: white !important;
    position: absolute;
    z-index: 999;
  }
  .imagewrap img{
    transform: scale(1.2);
  }
}

.mb-80{
   margin-bottom:5px;
}
.wordwrap{
  width:100%;
  height:20%;
  opacity: 0;
  padding:2px;
  position: absolute;
  top:40%;
  color: rgb(255, 255, 255) !important;
  font-weight: bolder;
  text-align: center;
  display: flex;
  font-size:1rem;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
// .wordwrap:hover{
//    font-size: 1rem;
// }
.wordwrap:hover{
  color: rgb(255, 255, 255) !important;
}

.card:after{
  content: '';
    width: 0%;
    height: 1px;
    border: 1.5px rgb(0, 255, 170) solid;
    background-color: #000;
    position: absolute;
    bottom:0;
    left: 0;
    transition: all .3s linear;
    z-index: 999;
}

.card:hover::after{
   width: 100%;
}


.shut{
  width:200px;
  height:80px;
  transform: rotate(-30deg);
  position: absolute;
  border-radius:40%;
  background-color: #000;
  top: -65%;
  left:-50%;
  z-index: 999;
  transition: 0.5s ease-in-out;
}

// .eyes:hover{
//   .shut{
//  top:-6%;
//   }
// }


summary{
   margin-bottom: 1rem;
   outline: none;
   position: relative;
   font-size: 1.2rem;
}

details >summary::before{
   position: absolute;
   right: 20px;
 
  }
  details{
   margin-bottom: 2rem;
   
  }

  details[open] summary ~ * {
  animation: sweep .5s ease-in-out ;
}


@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 0px}

}

.forwords{
  background-color: springgreen;
}

@media only screen and (max-width:768px){
.banner-box{
  height: 400px;
}
}
@media only screen and (max-width:720px) {
  .banner-box{
    width: 100vw;
    height: 290px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    margin-top:4rem;
  }
  .banner-back{
    height:80%;
    overflow: hidden;
    max-width: 100%;
  }
  .fvl-logo-img{
    width: 80%;
    top:20%;
  }
  .ticket_buttons{
    flex-direction: column;
  }
  .fvl_about{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100% !important;
    height: auto;
    padding:1rem;
  }
  .fvl_icon{
    margin-bottom: 2rem;
  }
  .early-bird{
    width: 10em;
    height:4em;
  }
  .early-bird:hover::before{
    width:10em;
  }
  .pre-sale{
    width: 10em;
    height:4em;
  }
  .pre-sale:hover::before{
    width:10em;
  }
  .reqular-tickets{
    width: 10em;
    height:4em;
  }
  .reqular-tickets:hover::before{
    width:10em;
  }
  .students-tickets{
    width: 10em;
    height:4em;
  }
  .students-tickets:hover::before{
    width:10em;
  }
  .about_fvl{
    width: 100% !important;;
    height:100%;
  }
  .early-bird-price{
    margin-left: 1rem;
  }
  .early-bird-time{
    margin-left: 1rem;
  }
  .pre-sale-time{
    margin-left: 1rem;
  }
  .regular-time{
   margin-left:1rem

  }
  .student-time{
    margin-left: 1rem;
  }
  .sales-detail{
    font-size: 0.8rem;
    margin-left: 1rem;
  }
  .background2{
    width: 100vw;
    height: auto;
  }
  .mb-80{
    margin-bottom: 30px !important
  }
  .container{
    width: 100%;
  }
  .card{
    width: 100%;
  }
  .imagewrap{
    width: 100%;
  }
  .faq-container{
    width: 100%;
    padding:0.5rem;
  }
}




// .early-bird.clicker{
// .ticket-price{
//   animation: test 5s linear;
// }
// @keyframes test{
//   0% {opacity: 0;}

//   100%{opacity:1}
// }

// }






// -------------slider2----------------


</style>

